<template>
  <div class="wrapper">
    <div class="card">
      <div class="title">
        <div class="caption">{{ $t('google.application') }}</div>
      </div>
      <div class="mt-4">
        <span>{{ $t('google.setting_note') }}</span>
      </div>
      <div class="mt-5">
        <button class="btn btn-solid btn-blue" @click="auth">{{ $t('start_auth') }}</button>
      </div>
      <div class="mt-4">
        <div class="link link-info">
          <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'setting.google.flow',
  data () {
    return {
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'setting.index'
      })
    }
  },
  methods: {
    auth () {
      this.$router.push({
        name: 'setting.google.step1',
        params: {
          mode: true
        }
      })
    },
    cancelled () {
      this.$router.push({
        name: 'setting.index'
      })
    }
  }
}
</script>
